<template>
    <b-container>
        <div class="mb-4">
            <p class="txt_secondary text-uppercase mt-3">
                <strong>Información del paciente</strong>
            </p>
            <div class="contenedor">
                <div class="data mr-1">
                    <div class="th_style">
                        <span class="txt_secondary size-span pl-2"><strong>Paciente</strong>
                            <div class="data-result pl-2 py-1"><span>{{ (dtaPaciente.nombre) }}</span></div>
                        </span>
                    </div>
                </div>
                <div class="data mr-1">
                    <div class="th_style">
                        <span class="txt_secondary size-span pl-2"><strong>Expediente</strong>
                            <div class="data-result pl-2 py-1"><span>{{ dtaPaciente.expediente }}</span></div>
                        </span>
                    </div>
                </div>
                <div class="data mr-1" v-if="dtaPaciente.no_expediente_legacy">
                    <div class="data-title th_style">
                        <span class="txt_secondary size-span pl-2"><strong>Expediente fisico</strong>
                            <div class="data-result pl-2 py-1"><span>{{ dtaPaciente.no_expediente_legacy }}</span></div>
                        </span>
                    </div>
                </div>
                <div class="data mr-1">
                    <div class="data-title th_style">
                        <span class="txt_secondary size-span pl-2"><strong>Género</strong>
                            <div class="data-result pl-2 py-1"><span>{{ dtaPaciente.genero }}</span></div>
                        </span>
                    </div>
                </div>
                <div class="data mr-1">
                    <div class="data-title th_style">
                        <span class="txt_secondary size-span pl-2"><strong>Fecha de nacimiento</strong>
                            <div class="data-result pl-2 py-1"><span>{{ dtaPaciente.fecha_nacimiento }}</span></div>
                        </span>
                    </div>
                </div>
                <div class="data mr-1">
                    <div class="data-title th_style">
                        <span class="txt_secondary size-span pl-2"><strong>Edad</strong>
                            <div class="data-result pl-2 py-1"><span>{{ dtaPaciente.edad }}</span></div>
                        </span>
                    </div>
                </div>
                <!-- <div class="data mr-1">
                    <div class="data-title th_style">
                        <span class="txt_secondary size-span pl-2"><strong>Dirección</strong>
                            <div class="data-result pl-2 py-1"><span>{{ dtaPaciente.direccion }}</span></div>
                        </span>
                    </div>
                </div> -->
            </div>

            <!-- <p class="txt_secondary text-uppercase mt-4 mb-3">
                <strong>Información del responsable</strong>
            </p>
            <div class="contenedor">
                <div class="data mr-1">
                    <div class="data-title th_style">
                        <span class="txt_secondary size-span pl-2"><strong>Responsable del paciente</strong>
                            <div class="data-result pl-2 py-1"><span>{{ dtaResponsable.nombre }}</span></div>
                        </span>
                    </div>
                </div>
                <div class="data mr-1">
                    <div class="data-title th_style">
                        <span class="txt_secondary size-span pl-2"><strong>parentesco</strong>
                            <div class="data-result pl-2 py-1"><span>{{ dtaResponsable.parentesco }}</span></div>
                        </span>
                    </div>
                </div>
            </div> -->

            <p class="txt_secondary text-uppercase mt-4 mb-3">
                <strong>Datos médicos</strong>
            </p>
            <div class="contenedor">
                <div class="data mr-1">
                    <div class="data-title th_style">
                        <span class="txt_secondary size-span pl-2"><strong>Médico tratante</strong>
                            <div class="data-result pl-2 py-1"><span>{{ dtaMedico.medico }}</span></div>
                        </span>
                    </div>
                </div>
                <div class="data mr-1">
                    <div class="data-title th_style">
                        <span class="txt_secondary size-span pl-2"><strong>Área/Especialidad</strong>
                            <div class="data-result pl-2 py-1"><span>{{ dtaMedico.area }}</span></div>
                        </span>
                    </div>
                </div>
                <div class="data mr-1">
                    <div class="data-title th_style">
                        <span class="txt_secondary size-span pl-2"><strong>Habitación</strong>
                            <div class="data-result pl-2 py-1"><span>{{ dtaMedico.habitacion }}</span></div>
                        </span>
                    </div>
                </div>
                <div class="data mr-1">
                    <div class="data-title th_style">
                        <span class="txt_secondary size-span pl-2"><strong>Alergias</strong>
                            <div class="data-result pl-2 py-1"><span>{{ dtaMedico.alergias }}</span></div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>

export default {
    name: "DatosEnfermeria",
    props: ['paciente'],
    data: () => ({
        dtaPaciente: {
            nombre: "",
            fecha_nacimiento: "",
            edad: "",
            expediente: "",
            genero: "",
            direccion: "",
            no_expediente_legacy: null
        },
        dtaResponsable: {
            nombre: "",
            parentesco: ""
        },
        dtaMedico: {
            medico: "",
            area: "",
            habitacion: "",
            alergias: ""
        }
    }),
    created() {
        this.$watch('paciente', (newPaciente) => {
            this.setData(newPaciente)
        })
    },
    methods: {
        setData(paciente) {

            this.dtaPaciente.nombre = paciente.paciente.nombre
            this.dtaPaciente.fecha_nacimiento = paciente.paciente.fecha_nacimiento
            this.dtaPaciente.edad = paciente.paciente_edad_years + " años " + paciente.paciente_edad_months + " meses"
            this.dtaPaciente.expediente = paciente.no_expediente
            this.dtaPaciente.no_expediente_legacy = paciente.paciente.no_expediente_legacy
            this.dtaPaciente.genero = paciente.paciente.genero.nombre
            this.dtaPaciente.direccion = paciente.paciente.domicilio + ", " + paciente.paciente.colonia + ", " + paciente.paciente.ciudad + ", " + paciente.paciente.municipio.nombre + ", " + paciente.paciente.estado.nombre

            this.dtaResponsable.nombre = paciente.responsable_paciente.nombre
            this.dtaResponsable.parentesco = paciente.responsable_paciente.parentezco

            this.dtaMedico.medico = paciente.medico.nombre
            this.dtaMedico.area = paciente.especialidad.nombre
            this.dtaMedico.habitacion = paciente.no_habitacion
            this.dtaMedico.alergias = paciente.alergias ? paciente.alergias : "sin alergias"
        },
    },
    mounted() {
    },
};
</script>

<style>
.contenedor {
    display: flex;
    flex-wrap: wrap;
}

.data {
    flex: auto;
    flex-wrap: wrap;
    border-right: none !important;
    margin-top: 3px;
}

.data-result {
    display: flex;
    border: none !important;
    background-color: white !important;
    ;
}
</style>