<template>
    <div>
        <Navbar />
        <b-container class="mt-5">
            <b-row>
                <b-col cols="12" sm="12" md="12" lg="3">
                    <button @click="goBack()" class="py-2 mb-4 text-left border_secoundary btn_radius btn-block">
                        <i class="fa fa-arrow-left fa-lg px-2" />
                        Regresar
                    </button>
                    <hr>
                    <button @click="goHemodinamia()"
                        :class="{ btn_menu_pressed: activeHemodinamia, border_primary: !activeHemodinamia }"
                        class="py-2 mb-4 text-left btn_radius btn-block">
                        <i class="fa fa-user-plus fa-lg px-2" />
                        Hemodinamia
                    </button>
                    <button @click="goRegistrroClinico()"
                        :class="{ btn_menu_pressed: activeClinico, border_primary: !activeClinico }"
                        class="py-2 mb-4 text-left btn_radius btn-block">
                        <i class="fa fa-user-plus fa-lg px-2" />
                        Registros clínicos
                    </button>
                    <button @click="goQuirofano()"
                        :class="{ btn_menu_pressed: activeQuirofano, border_primary: !activeQuirofano }"
                        class="py-2 mb-4 text-left btn_radius btn-block">
                        <i class="fa fa-user-plus fa-lg px-2" />
                        Quirófano
                    </button>
                    <button @click="goCirugia()"
                        :class="{ btn_menu_pressed: activeCirugia, border_primary: !activeCirugia }"
                        class="py-2 mb-4 text-left btn_radius btn-block">
                        <i class="fa fa-user-plus fa-lg px-2" />
                        Cirugía segura
                    </button>
                </b-col>

                <b-col cols="12" sm="12" md="12" lg="9">
                    <b-container>
                        <b-row class="justify-content-between">
                            <b-col cols="12" sm="12" md="12" lg="12">
                                <h2 class="title_forms txt_primary text-uppercase pl-3">{{ title }}</h2>
                            </b-col>
                        </b-row>
                        <ListadoExpedientes @changeExp="changeExp" />
                        <DatosEnfermeria :paciente="paciente" />
                    </b-container>
                    <router-view />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import ListadoExpedientes from '../../components/Expediente/ListadoExpedientes.vue'
import DatosEnfermeria from '../../components/Paciente/Visualizar/DatosEnfermeria.vue'

export default {
    name: 'Main',
    components: { Navbar, ListadoExpedientes, DatosEnfermeria },

    data: () => ({
        activeInformacion: true,
        activeHemodinamia: false,
        activeClinico: false,
        activeQuirofano: false,
        activeCirugia: false,
        paciente: {},
        title:"",
    }),

    watch: {
        $route() {
            this.setActiveRoutes()
        },
    },

    computed: {
        currentRouteName() {
            return this.$route.path
        }
    },

    methods: {
        changeExp(event) {
            this.paciente = event
        },
        setActiveRoutes() {
            const route = this.currentRouteName
            if (route.includes('/enfermeria/registro/hemodinamia')) {
                this.activeHemodinamia = true
                this.activeClinico = false
                this.activeQuirofano = false
                this.activeCirugia = false
                this.title='Hoja de efermería de hemodinamia'
            } else if (route.includes('/enfermeria/registro/clinico')) {
                this.activeHemodinamia = false
                this.activeClinico = true
                this.activeQuirofano = false
                this.activeCirugia = false
                this.title='Hoja de Registros Clínicos de Enfermería'
            } else if (route.includes('/enfermeria/registro/quirofano/preoperatorio')) {
                this.activeHemodinamia = false
                this.activeClinico = false
                this.activeQuirofano = true
                this.activeCirugia = false
                this.title='Hoja de enfermeria quirófano'
            } else if (route.includes('/enfermeria/registro/cirugia/entrada')) {
                this.activeHemodinamia = false
                this.activeClinico = false
                this.activeQuirofano = false
                this.activeCirugia = true
                this.title='Hoja de verificación de cirugía segura'
            }
        },

        goHemodinamia() {
            this.$router.push({
                name: 'registroHemodinamia'
            })

            this.activeHemodinamia = true
            this.activeClinico = false
            this.activeQuirofano = false
            this.activeCirugia = false
        },

        goRegistrroClinico() {
            this.$router.push({
                name: 'registroClinico'
            })

            this.activeHemodinamia = false
            this.activeClinico = true
            this.activeQuirofano = false
            this.activeCirugia = false
        },

        goQuirofano() {
            this.$router.push({
                name: 'preoperatorio'
            })

            this.activeHemodinamia = false
            this.activeClinico = false
            this.activeQuirofano = true
            this.activeCirugia = false
        },

        goCirugia() {
            this.$router.push({
                name: 'entradaCirugia'
            })

            this.activeHemodinamia = false
            this.activeClinico = false
            this.activeQuirofano = false
            this.activeCirugia = true
        },

        goBack() {
            this.$router.push({
                name: 'pacientesEnfermeria'
            })
        }
    },

    mounted() {
        this.paciente_id = this.$route.params.id
        this.setActiveRoutes()
    }

}
</script>

<style scoped></style>