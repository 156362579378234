<template>
    <b-container>
        <p class="txt_secondary text-uppercase mt-1"><strong>Lista de expedientes</strong></p>
        <b-table-simple class="table-sm border_table">
            <b-tbody>
                <b-tr>
                    <b-th class="th_style size_th">Expediente</b-th>
                    <b-td class="border_table">
                        <b-form-select v-model="idExpediente" @change="changeExp(idExpediente)" class="border-0" size="sm">
                            <template>
                                <b-form-select-option v-for="(all) of allExp" :key="all.id" :value="all.id">{{
                                    all.no_expediente }}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </b-container>
</template>

<script>
import { url } from '../../../settings'
import router from "../../router";
import CryptoJS from 'crypto-js'
export default {
    data: () => ({
        allExp: [],
        idExpediente: "hjgjhgjhg",
    }),
    methods: {
        changeExp(id) {
            console.log(this.allExp);
            this.loading = true
            const exps = this.allExp
            const currentExp = exps.find(exp => exp.id == id)
            if (currentExp) {
                this.idExpediente = currentExp.id
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(currentExp), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                if (this.$route.params.idExp!=this.idExpediente) {
                    router.push({ name: this.$route.name, params: { id: this.$route.params.id, idExp: this.idExpediente } }).catch(()=>{});
                }
                console.log(currentExp);
                this.$emit('changeExp', currentExp)
            }
        },
        async allExpedientes() {
            try {
                const res = await fetch(`${url}/api/datos_medicos/` + this.$route.params.id, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                this.allExp = data.expedientes
                this.changeExp(this.$route.params.idExp)

            } catch (error) {
                console.log("el error es" + error);
            }
        },
    },
    mounted() {
        this.idExpediente = this.$route.params.idExp
        this.allExpedientes()
    }

}
</script>

<style></style>